import React from "react";

const Management = () => {
  return (
    <section className="management-area pt-100 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-content text-center">
              <h1 className="fs-50 pb-2 fw-bold">
                Optimisez votre gestion financière avec un audit exhaustif par
                le <u className="under-line">leader du marché</u>
              </h1>
              <p className="fs-18">
                Depuis 2019, nous accompagnons les entreprises CAC40 et SBF120,
                en protégeant leurs finances. Au-delà de la récupération des
                trop-payés, nous scrutons avec rigueur les contrats pour des
                conditions commerciales optimales.
              </p>
            </div>
            <div className="management-content pt-60">
              {managementData.map((item) => (
                <div className="management-item" key={item.id}>
                  <div className="number">{item.id}</div>
                  <div className="text">
                    <div className="fs-20 fw-bold">{item.title}</div>
                    <div className="fs-18">{item.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const managementData = [
  {
    id: 1,
    title: "Collecte et traitement des données:",
    desc: "Nous rassemblons vos informations et nous plongeons dans vos bases informatiques.",
  },
  {
    id: 2,
    title: "Audit informatique et rapprochement",
    desc: "Nous procédons à un audit minutieux et effectuons un rapprochement avec les pièces justificatives.",
  },
  {
    id: 3,
    title: "Circularisation et analyse des comptes",
    desc: "Nous établissons une liste des fournisseurs à circulariser et entreprenons une analyse approfondie des extraits de comptes.",
  },
  {
    id: 4,
    title: "Examen des contrats",
    desc: "Notre équipe évalue en détail les conditions commerciales, sur facture et hors facture, et examine les variations de tarif ainsi que l'effet de l'inflation conjoncturelle.",
  },
  {
    id: 5,
    title: "Validation et recouvrement",
    desc: "Au fil de l'audit, nous vous présentons les anomalies détectées pour validation, et nous chargeons du recouvrement auprès des fournisseurs.",
  },
  {
    id: 6,
    title: "Rapport final",
    desc: "À l'issue de notre mission, nous vous fournirons un rapport détaillé comprenant l'ensemble des anomalies et des recommandations pour mettre en place les actions correctives adaptées.",
  },
];

export default Management;
