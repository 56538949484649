import React from "react";
import Layout from "../../Components/Layout";
import Banner from "./components/Banner";
import Passion from "./components/Passion";
import Technologie from "./components/Technologie";
import Future from "./components/Future";

const Home = () => {
  return (
    <div className="home-wrapper">
      <Layout>
        <Banner />
        <Passion/>
        <Technologie/> 
        <Future/>
      </Layout>
    </div>
  );
};

export default Home;
