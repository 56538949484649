import React from "react";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lgHash from "lightgallery/plugins/hash";
import lgVdeo from "lightgallery/plugins/video";
import technologie from "../../../Assets/images/home/technologie.jpg";
import PlayBtn from "../../../Assets/Icons/PlayBtn";
import { Image } from "react-bootstrap";

const Technologie = () => {
  return (
    <section className="technologies-area secondary-bg pt-100 pb-100">
      <div className="container">
        <div className="row row-gap-30">
          <div className="col-lg-5">
            <div className="section-content">
              <span className="fs-15 fw-semibold sub-titles secondary-sub-title">
                Lorsque l'intelligence artificielle <br /> rencontre l'expertise
                comptable.
              </span>
              <h1 className="fs-50 mt-3 pb-2 fw-bold text-white">
                Technologie & Intuition
              </h1>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="section-content">
              <p className="fs-17 pt-0 text-white">
                Le monde évolue rapidement, et la comptabilité ne fait pas
                exception. En alliant technologie avancée à l'intuition humaine,
                nous déchiffrons vos données avec une précision inégalée.
              </p>
              <br />
              <p className="fs-17 text-white">
                Laissez WBC vous guider à travers cette nouvelle ère comptable,
                où chaque détail compte, et où chaque décision est éclairée par
                des données fiables.
              </p>
            </div>
          </div>
          <div className="col-lg-12 pt-3">
            <LightGallery
              plugins={[lgZoom, lgShare, lgHash, lgVdeo]}
              speed={500}
              elementClassNames="gallery"
            >
              <div
                className="gallery__item"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=l-aV3qt6dKw"
                data-poster={technologie}
              >
                <Image
                  className="img-responsive img-fluid border-radius-20"
                  src={technologie}
                />
                <button className="play-btn">
                  <PlayBtn />
                </button>
              </div>
            </LightGallery>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technologie;
