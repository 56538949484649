import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lgHash from "lightgallery/plugins/hash";
import lgVdeo from "lightgallery/plugins/video";
import technologie from "../../Assets/images/outvision/video.jpg";
import PlayBtn from "../../Assets/Icons/PlayBtn";
import tradition from "../../Assets/images/outvision/transparence.jpg";
import { Image } from "react-bootstrap";

const OurVision = () => {
  return (
    <div className="vision-wrapper footer-white">
      <Layout>
        <section className="about-page-area pt-100 pb-100 vision-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-content text-center sub-titles banner-sub">
                  <span className="fs-15 fw-semibold sub-titles">
                    Innover, Transformer, Prospérer.
                  </span>
                  <h1 className="fs-60 mt-3 fw-bold">
                    Le Futur de la Comptabilité & Analyse{" "}
                    <u className="under-line">Contractuelle </u>
                    est Ici
                  </h1>
                  <p className="fs-18">
                    La comptabilité n'est qu'une partie de l'histoire. Nos
                    services s'étendent pour englober chaque aspect de vos
                    données d'entreprise, y compris l'analyse minutieuse de vos
                    contrats et accords.
                  </p>
                  <br />
                  <p className="fs-18">
                    Notre vision est de repenser ces domaines pour un avantage
                    compétitif inégalé, soutenu par la confiance et les
                    recommandations de nos clients fidèles.
                  </p>
                  <div className="section-content-btn justify-content-center">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Commencer
                    </Link>
                    <Link
                      to=""
                      className="btn-lg primary-border-btn fs-18 fw-semibold br-10"
                    >
                      Trouvez notre plus
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 pt-60">
                <LightGallery
                  plugins={[lgZoom, lgShare, lgHash, lgVdeo]}
                  speed={500}
                  elementClassNames="gallery"
                >
                  <div
                    className="gallery__item"
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=l-aV3qt6dKw"
                    data-poster={technologie}
                  >
                    <Image
                      className="img-responsive img-fluid border-radius-20"
                      src={technologie}
                    />
                    <button className="play-btn">
                      <PlayBtn />
                    </button>
                  </div>
                </LightGallery>
              </div>
            </div>
          </div>
        </section>
        <section className="secondary-bg primary-shape-bg pt-100 pb-100 transparence-area">
          <div className="container">
            <div className="row row-gap-40">
              <div className="col-xl-5 col-lg-6 align-self-center">
                <div className="section-content">
                  <span className="fs-15 fw-semibold sub-titles secondary-sub-title">
                    Un engagement sans compromis.
                  </span>
                  <h1 className="fs-50 mt-3 pb-2 fw-bold text-white">
                    Partenariat & <br />{" "}
                    <u className="under-line">Transparence</u>
                  </h1>
                  <p className="fs-18 text-white">
                    Chaque collaboration est basée sur une confiance mutuelle.
                    Nous croyons fermement en la transparence, la communication
                    ouverte et le dévouement à la réussite de nos clients.
                  </p>
                  <br />
                  <p className="fs-18 text-white">
                    Nous sommes plus qu'un simple prestataire; nous sommes votre
                    partenaire. Ensemble, nous bâtirons l'avenir de votre
                    entreprise sur des fondations solides.
                  </p>
                  <div className="section-content-btn justify-content-start text-start ">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Partageons une vision
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1 col-lg-6 align-self-center ">
                <div className="ms-lg-5 passion-img">
                  <img
                    src={tradition}
                    alt={tradition}
                    className="img-fluid border-radius-20"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default OurVision;
