import React from "react";
import passion from "../../../Assets/images/home/passion.jpg";

const Passion = () => {
  return (
    <section className="pt-120 pb-120 passing-area">
      <div className="container">
        <div className="row row-gap-40">
          <div className="col-xl-5 col-lg-6 align-self-center">
            <div className="section-content">
              <span className="fs-15 fw-semibold sub-titles">
                Alliez technologie de pointe <br /> et expertise comptable.
              </span>
              <h1 className="fs-50 mt-3 pb-2 fw-bold">
                Vos Données, <br /> Notre <u className="under-line">Passion</u>
              </h1>
              <p className="fs-18">
                Notre mission ? Placer l'innovation au cœur de votre
                comptabilité. Notre équipe combinant data scientists et
                comptables scrute chaque détail avec une précision chirurgicale.
              </p>
              <br />
              <p className="fs-18">
                Libérez-vous des erreurs marginales qui grèvent votre chiffre
                d'affaires. Nous mettons à votre disposition des outils
                sur-mesure pour une vision claire de votre situation financière.
              </p>
            </div>
          </div>
          <div className="col-xl-6 offset-xl-1 col-lg-6 align-self-center">
            <div className="ps-lg-5 passion-img">
              <img
                src={passion}
                alt={passion}
                className="img-fluid border-radius-20"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Passion;
