import React from "react";
import future from "../../../Assets/images/home/future.jpg";
import { Link } from "react-router-dom";
const Future = () => {
  return (
    <section className="pt-120 pb-120 future-area">
      <div className="container">
        <div className="row row-gap-40">
          <div className="col-xl-5 col-lg-6 align-self-center">
            <div className="section-content">
              <span className="fs-15 fw-semibold sub-titles">
                Préparez-vous pour demain <br /> avec WBC aujourd'hui.
              </span>
              <h1 className="fs-50 mt-3 pb-2 fw-bold">
                Un Pas Vers <br /> <u className="under-line"> L'Avenir</u>
              </h1>
              <p className="fs-18">
              L'avenir de votre entreprise se construit aujourd'hui. Avec WBC, bénéficiez d'un partenaire qui non seulement comprend les défis du présent, mais anticipe également ceux de demain.
              </p>
              <br />
              <p className="fs-18">
              Notre ambition ? Que vous soyez toujours un pas en avance, prêt à saisir chaque opportunité et à naviguer avec succès à travers les défis.
              </p>
              <div className="section-content-btn justify-content-start text-start ">
                <Link
                  to=""
                  className="btn-lg primary-btn fs-18 fw-semibold br-10"
                >
                  Découvrez comment
                </Link> 
              </div>
            </div>
          </div>
          <div className="col-xl-6 offset-xl-1 col-lg-6 align-self-center ">
            <div className="ms-lg-5 passion-img">
              <img
                src={future}
                alt={future}
                className="img-fluid border-radius-20"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Future;
