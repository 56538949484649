import React from "react";
import ensemble from "../../../Assets/images/contact/ensemble.jpg";

const Transformation = () => {
  return (
    <>
      <section className="secondary-bg primary-shape-bg pt-100 pb-100 transformation-area">
        <div className="container">
          <div className="row row-gap-40">
            <div className="col-lg-6 align-self-center ">
              <div className="ms-lg-5 passion-img">
                <img
                  src={ensemble}
                  alt={ensemble}
                  className="img-fluid border-radius-20"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 align-self-center">
              <div className="section-content">
                <span className="fs-15 fw-semibold sub-titles secondary-sub-title">
                  Métamorphosez vos finances et vos <br /> accords avec notre
                  expertise.
                </span>
                <h1 className="fs-50 mt-3 pb-2 fw-bold text-white">
                  Plus qu'un Service, Une{" "}
                  <u className="under-line">Transformation</u>
                </h1>
                <p className="fs-18 text-white">
                  Ne vous contentez pas de repérer les erreurs, transformez-les
                  en atouts. Nos solutions ne se limitent pas à la simple
                  identification, mais à la mise en place de systèmes robustes
                  pour une croissance pérenne.
                </p>
                <br />
                <p className="fs-18 text-white">
                  Vos contrats et correspondances regorgent d'opportunités. Nous
                  mettons en lumière ces points souvent négligés, vous
                  permettant ainsi de maximiser la valeur à chaque étape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transformation;
