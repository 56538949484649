import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lgHash from "lightgallery/plugins/hash";
import lgVdeo from "lightgallery/plugins/video";
import technologie from "../../Assets/images/about/video.jpg";
import PlayBtn from "../../Assets/Icons/PlayBtn";
import tradition from "../../Assets/images/about/tradition.jpg";
import { Image } from "react-bootstrap";

const About = () => {
  return (
    <div className="about-wrapper">
      <Layout>
        <section className="about-page-area pt-100 pb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-content text-center sub-titles banner-sub">
                  <span className="fs-15 fw-semibold sub-titles">
                    Née d'une ambition, construite sur la persévérance
                    <br /> et la confiance de nos clients.
                  </span>
                  <h1 className="fs-60 mt-3 fw-bold">
                    WBC: <u className="under-line">L'Histoire </u>
                    d'une Vision
                  </h1>
                  <p className="fs-18">
                    Fondé par William Benoit, WBC est le fruit d'une quête
                    incessante d'excellence. Chaque victoire, chaque défi
                    surmonté, a forgé notre ADN d'entreprise.
                  </p>
                  <br />
                  <p className="fs-18">
                    Notre équipe, c'est une synergie unique de passionnés. Du
                    comptable au consultant, chacun apporte son expertise, sa
                    vision et son dévouement à l'excellence.
                  </p>
                  <div className="section-content-btn justify-content-center">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Commencer
                    </Link>
                    <Link
                      to=""
                      className="btn-lg primary-border-btn fs-18 fw-semibold br-10"
                    >
                      Trouvez notre plus
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 pt-60">
                <LightGallery
                  plugins={[lgZoom, lgShare, lgHash, lgVdeo]}
                  speed={500}
                  elementClassNames="gallery"
                >
                  <div
                    className="gallery__item"
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=l-aV3qt6dKw"
                    data-poster={technologie}
                  >
                    <Image
                      className="img-responsive img-fluid border-radius-20"
                      src={technologie}
                    />
                    <button className="play-btn">
                      <PlayBtn />
                    </button>
                  </div>
                </LightGallery>
              </div>
            </div>
          </div>
        </section>
        <section className="tradition-area pt-100 pb-100">
          <div className="container">
            <div className="row row-gap-40">
              <div className="col-xl-5 col-lg-6 align-self-center">
                <div className="section-content">
                  <span className="fs-15 fw-semibold sub-titles">
                    Quand technologie rime avec <br /> expertise humaine.
                  </span>
                  <h1 className="fs-50 mt-3 pb-2 fw-bold">
                    Innovation & <br /> <u className="under-line">Tradition</u>
                  </h1>
                  <p className="fs-18">
                    Chez WBC, nous avons une conviction profonde: le progrès
                    technologique ne doit pas éclipser la valeur de l'expertise
                    humaine. C'est pourquoi nous marions le meilleur des deux
                    mondes pour vous offrir une solution complète.
                  </p>
                  <br />
                  <p className="fs-18">
                    Avec WBC, bénéficiez de la rigueur de l'analyse
                    algorithmique et de la finesse de l'approche humaine pour
                    une comptabilité sans faille.
                  </p>
                  <div className="section-content-btn justify-content-start text-start ">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Rencontrez l'équipe derrière la magie
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1 col-lg-6 align-self-center ">
                <div className="ms-lg-5 passion-img">
                  <img
                    src={tradition}
                    alt={tradition}
                    className="img-fluid border-radius-20"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default About;
