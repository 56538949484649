import React from 'react';

const PlayBtn = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113" viewBox="0 0 113 113" fill="none">
            <circle cx="56.5" cy="56.5" r="38.5" fill="white" />
            <circle cx="56.5" cy="56.5" r="47.1625" stroke="white" stroke-opacity="0.25" stroke-width="17.325" />
            <path d="M71.8999 56.5001L48.7999 69.8369L48.7999 43.1633L71.8999 56.5001Z" fill="#26CD94" />
        </svg>
    );
};

export default PlayBtn;