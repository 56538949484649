import React from "react";
import Layout from "../../Components/Layout";
import Spectrum from "./components/Spectrum";
import Transformation from "./components/Transformation";
import Management from "./components/Management";
import Accompagnement from "./components/Accompagnement";
import Customized from "./components/Customized";

const Services = () => {
  return (
    <div className="services-wrapper">
      <Layout>
        <Spectrum />
        <Transformation/>
        <Management/>
        <Accompagnement/>
        <Customized/>
      </Layout>
    </div>
  );
};

export default Services;
