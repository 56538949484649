import React from 'react';
import MainMenu from './MainMenu';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <>
            <Header/>
            <MainMenu/>
            {children}
            <Footer/>
        </>
    );
};

export default Layout;