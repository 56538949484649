import React from 'react';
import Clock from '../Assets/Icons/Clock';
import Web from '../Assets/Icons/Web';
import Email from '../Assets/Icons/Email';
import Phone from '../Assets/Icons/Phone';

const Header = () => {
    return (
        <header className='header-area'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="header-address">
                            <ul>
                                <li><Clock/> Mon - Sat (8:00am - 6:00pm)</li>
                                <li><Web/> Wbcaudit.com</li>
                            </ul>
                            <ul>
                                <li><Email/> wbc1@consultant.com</li>
                                <li><Phone/> +(33) 06-71-64-19-37</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;