import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Logo from "../Assets/images/logo.svg";

const MainMenu = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeOffcanvas = () => setShowOffcanvas(false);

  return (
    <>
      <Navbar
        expand="lg"
        className={`main-menu ${isSticky ? "sticky" : ""}`} 
      >
        <Container>
          <Link to="/" className="navbar-brand py-0">
            <img src={Logo} alt="" />
          </Link>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            onClick={() => setShowOffcanvas((prev) => !prev)}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            show={showOffcanvas}
            onHide={closeOffcanvas}
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                <img src={Logo} alt="" height={40} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="main-menu-list justify-content-center align-items-center flex-grow-1 pe-3">
                {menuData.map((item) => (
                  <NavLink
                    to={item.url}
                    className="nav-link"
                    onClick={closeOffcanvas}
                  >
                    {item.text}
                  </NavLink>
                ))}
              </Nav>
              <div className="d-flex align-items-center mt-3 mt-lg-0 justify-content-center gap-3">
                <Link
                  to="/sign-in"
                  className="btn-nav primary-border-btn fs-18 fw-semibold br-10"
                  onClick={closeOffcanvas}
                >
                  Sign up
                </Link>
                <Link
                  to="/sign-up"
                  className="btn-nav primary-btn fs-18 fw-semibold br-10"
                  onClick={closeOffcanvas}
                >
                  Log in
                </Link>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

const menuData = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Services",
    url: "/services",
  },
  {
    text: "About us",
    url: "/about",
  },
  {
    text: "Our Vision",
    url: "/out-vision",
  },
  {
    text: "Contact us",
    url: "/contact",
  },
];

export default MainMenu;
