import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section className="banner-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-content text-center ">
              <span className="sub-titles banner-sub text-white fs-15 fw-semibold">
                Reprenez le contrôle, découvrez des profits cachés.
              </span>
              <h1 className="fs-60 mt-3 fw-bold text-white">
                Optimisez Votre Flux Fournisseur &{" "}
                <u className="under-line">Contrats</u>
              </h1>
              <p className="fs-18 text-white">
                Bienvenue chez WBC. Plus qu'une simple expertise comptable, nous
                transformons vos anomalies, contrats, accords, conventions, et
                même mails en opportunités financières majeures.
              </p>
              <br />
              <p className="fs-18 text-white">
                Nous nous assurons que chaque centime et chaque terme de contrat
                travaillent pour vous. Vos recommandations ont été notre force
                pendant 4 ans, témoignant de notre efficacité remarquable.
              </p>
              <div className="section-content-btn justify-content-center">
                <Link
                  to=""
                  className="btn-lg primary-btn fs-18 fw-semibold br-10"
                >
                  Commencer
                </Link>
                <Link
                  to=""
                  className="btn-lg white-border-btn fs-18 fw-semibold br-10"
                >
                  Trouvez notre plus
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
