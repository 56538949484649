import React from "react";
import { Link } from "react-router-dom";

const Customized = () => {
  return (
    <section className="customized-area pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-content">
              <span className="fs-15 fw-semibold sub-titles ">
                Parce que chaque entreprise <br /> a sa propre histoire.
              </span>
              <h1 className="fs-60 mt-3 pb-2 fw-bold">
                Personnalisé <br />
                <u className="under-line">Pour Vous</u>
              </h1>
              <p className="fs-18">
                Chez WBC, nous comprenons que chaque entreprise est unique.
                C'est pourquoi nous adoptons une approche sur mesure pour chaque
                client, garantissant ainsi des solutions qui répondent
                parfaitement à vos besoins.
              </p>
              <br />
              <p className="fs-18">
                Découvrez comment nos services peuvent être adaptés pour
                répondre aux spécificités de votre entreprise, garantissant une
                compatibilité et une efficacité maximales.
              </p>
              <div className="section-content-btn justify-content-start">
                <Link
                  to=""
                  className="btn-lg primary-btn fs-18 fw-semibold br-10"
                >
                  Offrez-vous une transformation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Customized;
