import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/home/Home";
import Services from "./Pages/services/Services";
import About from "./Pages/about/About";
import OurVision from "./Pages/ourVision/OurVision";
import Contact from "./Pages/contact/Contact";
function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
      setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
      }, 0);
    }, [location.pathname, location.search]);
    return children;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/services" element={<Services/>} />
            <Route path="/about" element={<About />} />
            <Route path="/out-vision" element={<OurVision/>} />
            <Route path="/contact" element={<Contact/>} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}


export default App;
