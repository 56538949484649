import React from "react";
import Slider from "react-slick";
import img1 from "../../../Assets/images/services/1.jpg";
import img2 from "../../../Assets/images/services/2.jpg";
import img3 from "../../../Assets/images/services/3.jpg";
import img4 from "../../../Assets/images/services/4.jpg";
import { Image } from "react-bootstrap";

const Accompagnement = () => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 4.1,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <section className="accompagnement-area pt-100 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-content text-center">
              <span className="fs-15 fw-semibold sub-titles">
                Parce que comprendre est aussi important qu'agir.
              </span>
              <h1 className="fs-50 pb-2 fw-bold">
                Formation & <u className="under-line">Accompagnement</u>
              </h1>
              <p className="fs-18">
                Outre nos services de comptabilité, nous offrons également des
                formations adaptées à vos besoins. Que vous cherchiez à former
                vos équipes ou à approfondir vos connaissances, WBC est là pour
                vous.
              </p>
              <br />
              <p className="fs-18">
                Chaque session est conçue pour vous offrir des perspectives
                claires, des outils applicables et une meilleure compréhension
                de vos finances.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-container pt-60">
        <Slider {...settings}>
          {imgData.map((item) => (
            <div className="slider-i" key={item}>
              <Image
                src={item.img}
                alt=""
                className="img-fluid border-radius-20"
              />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const imgData = [
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
];

export default Accompagnement;
