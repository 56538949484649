import React from "react";
import { Link } from "react-router-dom";
import services from "../../../Assets/images/services/services.jpg";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lgHash from "lightgallery/plugins/hash";
import lgVdeo from "lightgallery/plugins/video"; 
import PlayBtn from "../../../Assets/Icons/PlayBtn";
import { Image } from "react-bootstrap";

const Spectrum = () => {
  return (
    <>
      <section className="contact-nous-area pt-100 pb-100">
        <div className="container">
          <div className="row row-gap-40">
            <div className="col-lg-6 align-self-center">
              <div className="section-content">
                <span className="fs-15 fw-semibold sub-titles ">
                  D'une vérification comptable à l'analyse approfondie de
                  contrats.
                </span>
                <h1 className="fs-60 mt-3 pb-2 fw-bold">
                  Un Spectre de <br />
                  <u className="under-line">Services</u> Inégalé
                </h1>
                <p className="fs-18">
                  Chaque entreprise est unique. Notre gamme complète s'étend de
                  l'audit de transactions à l'analyse de vos accords,
                  conventions, et autres communications clés. 
                </p>
                <br />
                <p className="fs-18">
                  Pourquoi? Car ces documents sont également des trésors de
                  valeurs cachées. Chez WBC, chaque détail compte, car c'est
                  votre succès qui alimente notre réputation.
                </p>
                <div className="section-content-btn justify-content-start">
                  <Link
                    to=""
                    className="btn-lg primary-btn fs-18 fw-semibold br-10"
                  >
                    Commencer
                  </Link>
                  <Link
                    to=""
                    className="btn-lg primary-border-btn fs-18 fw-semibold br-10"
                  >
                    Trouvez notre plus
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center ">
              <div className="ms-lg-5 passion-img">
              <LightGallery
              plugins={[lgZoom, lgShare, lgHash, lgVdeo]}
              speed={500}
              elementClassNames="gallery"
            >
              <div
                className="gallery__item"
                data-lg-size="1280-720"
                data-src="https://www.youtube.com/watch?v=l-aV3qt6dKw"
                data-poster={services}
              >
                <Image
                  className="img-responsive img-fluid border-radius-20"
                  src={services}
                />
                <button className="play-btn">
                  <PlayBtn />
                </button>
              </div>
            </LightGallery> 
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Spectrum;
