import React from "react";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import tradition from "../../Assets/images/contact/ensemble.jpg";
import connection from "../../Assets/images/contact/connection.jpg";
const Contact = () => {
  return (
    <div className="contact-wrapper footer-white">
      <Layout>
        <section className="contact-nous-area contact-page-area pt-100 pb-100">
          <div className="container">
            <div className="row row-gap-40">
              <div className="col-lg-6 align-self-center">
                <div className="section-content">
                  <span className="fs-15 fw-semibold sub-titles ">
                    Votre succès est à portée de main.
                  </span>
                  <h1 className="fs-60 mt-3 pb-2 fw-bold">
                    <u className="under-line">Connectons</u>-Nous
                  </h1>
                  <p className="fs-18">
                    Avez-vous des questions, des préoccupations ou simplement
                    l'envie de discuter des possibilités ? Notre équipe dévouée
                    est prête à vous écouter et à vous guider vers une solution
                    adaptée.
                  </p>
                  <br />
                  <p className="fs-18">
                    Laissez-nous faire partie de votre aventure
                    entrepreneuriale. Chaque étape, chaque défi, nous sommes à
                    vos côtés pour garantir votre succès
                  </p>
                  <div className="section-content-btn justify-content-start">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Commencer
                    </Link>
                    <Link
                      to=""
                      className="btn-lg primary-border-btn fs-18 fw-semibold br-10"
                    >
                      Trouvez notre plus
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center ">
                <div className="ms-lg-5 passion-img">
                  <img
                    src={connection}
                    alt={connection}
                    className="img-fluid border-radius-20"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondary-bg primary-shape-bg ensemble-area pt-100 pb-100">
          <div className="container">
            <div className="row row-gap-40">
              <div className="col-lg-6 align-self-center ">
                <div className="ms-lg-5 passion-img">
                  <img
                    src={tradition}
                    alt={tradition}
                    className="img-fluid border-radius-20"
                  />
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 align-self-center">
                <div className="section-content">
                  <span className="fs-15 fw-semibold sub-titles secondary-sub-title">
                    Faites le premier pas vers l'excellence.
                  </span>
                  <h1 className="fs-50 mt-3 pb-2 fw-bold text-white">
                    Agissons{" "}
                    <u className="under-line">Ensemble</u>
                  </h1>
                  <p className="fs-18 text-white">
                    Ne vous contentez pas de repérer les erreurs,
                    transformez-les en atouts. Nos solutions ne se limitent pas
                    à la simple identification, mais à la mise en place de
                    systèmes robustes pour une croissance pérenne.
                  </p>
                  <br />
                  <p className="fs-18 text-white">
                    Vos contrats et correspondances regorgent d'opportunités.
                    Nous mettons en lumière ces points souvent négligés, vous
                    permettant ainsi de maximiser la valeur à chaque étape.
                  </p>
                  <div className="section-content-btn justify-content-start text-start ">
                    <Link
                      to=""
                      className="btn-lg primary-btn fs-18 fw-semibold br-10"
                    >
                      Faites le premier pas
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default Contact;
