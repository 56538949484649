import React from "react";
import logo from "../Assets/images/logo-white.svg";
import logo2 from "../Assets/images/logo.svg";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-area secondary-bg">
      <div className="container">
        <div className="row pt-80 pb-80 row-gap-40">
          <div className="col-lg-4">
            <div className="footer-action">
              <Link to="">
                <img src={logo} alt="" className="white-logo" />
                <img src={logo2} alt="" className="black-logo" />
              </Link>
              <form action="" className="pt-50">
                <label className="fs-18 fw-bold text-white pb-3">
                  Susbcribe to our newsletter For <br /> Latest News and update
                </label>
                <div className="search-box">
                  <input type="email" placeholder="Email address" />
                  <button type="submit" className="primary-btn">
                    souscrire
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="footer-wedged ms-lg-5 ps-lg-4">
              <div className="footer-wedged-item">
                <div className="fs-18 fw-bold text-white pb-25">Links</div>
                <ul>
                  {menuData.map((item) => (
                    <li key={item}>
                      <Link to={item.url}>{item.text}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer-wedged-item">
                <div className="fs-18 fw-bold text-white pb-25">Contact us</div>
                <ul>
                  <li>
                    <Link>+(33) 06-71-64-19-37</Link>
                  </li>
                  <li>
                    <Link to="">wbc1@consultant.com</Link>
                  </li>
                  <li>
                    <Link to="">Wbcaudit.com</Link>
                  </li>
                  <li>
                    <Link>
                      Mon - Sat (8:00am - <br /> 6:00pm)
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-wedged-item">
                <div className="fs-18 fw-bold text-white pb-25">
                  Get in touch with us
                </div>
                <ul>
                  {socialData.map((item) => (
                    <li key={item}>
                      <Link to={item.url}>
                        {item.icon} {item.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright">
              <div className="fs-18 text-white">
                Copyright © 2023 WBC. All rights reserved.
              </div>
              <div className="fs-18 text-white">
                TERMS AND CONDITION | PRIVACY POLICY
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const menuData = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Services",
    url: "/services",
  },
  {
    text: "About us",
    url: "/about",
  },
  {
    text: "Our Vision",
    url: "/out-vision",
  },
  {
    text: "Contact us",
    url: "/contact",
  },
];

const socialData = [
  {
    icon: <FaFacebook />,
    text: "Facebook",
    url: "",
  },
  {
    icon: <FaInstagram />,
    text: "Instagram",
    url: "",
  },
  {
    icon: <FaYoutube />,
    text: "Youtube",
    url: "",
  },
  {
    icon: <FaTwitter />,
    text: "Twitter",
    url: "",
  },
];

export default Footer;
